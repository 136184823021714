@import 'themes';

@import '~@nebular/theme/styles/globals';
@import '~@nebular/auth/styles/globals';

@include nb-install() {
  @include nb-theme-global();
  @include nb-auth-global(); // append the install mixin inside of the nb-install
};
/* You can add global styles to this file, and also import other style files */
